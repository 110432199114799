/* main file */
(function ($, app) {
  // Immediately (before anything goes)
  (function () {})();
  // When everything is rendered
  $(window).on('load', function () {
    app.header();
    app.general();
    app.search();
    app.toggleHeaderWhenScrolling();
    app.m01();
    app.m03();
    app.m04();
    app.m05();
    app.m06();
    app.m07();
    app.m07o();
    app.m09();
    app.m10();
    app.m11();
    app.m12b();
    app.m13();
    app.m14();
    app.m15();
    app.m16();
    app.m17();
    app.m18();
    app.m20();
    app.m22();
    app.m23();
    app.m25();
    app.m26();
    app.m27();
    app.m28();
    app.m29();
    app.m30();
    app.m34();
    app.m35();
    app.m36();
    app.m37();
    app.m40();
    app.m41();
    app.m42();
    app.m44();
    app.m46();
    app.m47();
    app.m49();
    app.m51();
    app.m52();
    app.m53();
    app.m57();
  });
})(jQuery, app);
